<template>
    <div>
      <div class="searchBox">
        <div class="searchLeft">
          <div>
            <el-input
                size="medium"
                clearable
                v-model="searchForm.userId"
                placeholder="请输入UID"
                @keyup.enter.native="getTableList"
            ></el-input>
          </div>
          <div>
            <el-input
                size="medium"
                clearable
                v-model="searchForm.orderId"
                placeholder="请输入平台订单号"
                @keyup.enter.native="getTableList"
            ></el-input>
          </div>
          <div>
            <el-date-picker
              size="medium"
              v-model="searchForm.startTime"
              type="date"
              value-format="timestamp"
              @change="getTableList"
              placeholder="开始时间"
            ></el-date-picker>
            <span> — </span>
            <el-date-picker
              size="medium"
              v-model="searchForm.endTime"
              type="date"
              value-format="timestamp"
              @change="getTableList"
              placeholder="结束时间"
            ></el-date-picker>
          </div>
          <div>
            <el-select
              clearable
              size="medium"
              v-model="searchForm.payType"
              placeholder="支付类型"
              @change="getTableList"
            >
              <el-option
                v-for="(item,key) in typeOptions"
                :key="Number(key)"
                :label="item"
                :value="Number(key)">
              </el-option>
            </el-select>
          </div>
          <div v-if="searchForm.payType==3">
            <el-select
              clearable
              size="medium"
              v-model="searchForm.payChannel"
              placeholder="支付渠道"
              v-if="searchForm.payType==3"
              @change="getTableList"
            >
              <el-option
                v-for="(item,key) in channelOptions"
                :key="key"
                :label="item"
                :value="key">
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select
              clearable
              size="medium"
              v-model="searchForm.source"
              @change="getTableList"
              placeholder="APP"
            >
              <el-option
                v-for="(item,key) in appOptions"
                :key="key"
                :label="item"
                :value="key">
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select
              clearable
              size="medium"
              v-model="searchForm.orderStatus"
              @change="getTableList"
              placeholder="状态"
            >
              <el-option
                v-for="(item,key) in statusOptions"
                :key="key"
                :label="item"
                :value="key">
              </el-option>
            </el-select>
            </div>
          <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button>
          <el-button size="medium" type="primary" icon="el-icon-download" @click="exportExcel">导出</el-button>
        </div>
        <div class="searchRight">
  
        </div>
      </div>
      <el-table border :data="tableData" height="76vh" style="width: 99%" @sort-change="changeSort">
        <el-table-column prop="userId" label="UID"></el-table-column>
        <el-table-column prop="nickname" label="昵称"></el-table-column>
        <el-table-column prop="id" label="充值APP">
          <template slot-scope="scope">
            <span>{{appOptions[scope.row.source]}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderId" label="平台订单号" width="180"></el-table-column>
        <el-table-column prop="id" label="充值类型">
          <template slot-scope="scope">
            <span>{{typeOptions[scope.row.payType]}}</span>
            <p v-if="scope.row.payType==3">{{channelOptions[scope.row.payChannel]||'meta-go'}}</p>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="id" label="商品类型">
          <template slot-scope="scope">
            <span>{{typeOptions[scope.row.payType]}}</span>
          </template>
        </el-table-column> -->
        
        <el-table-column prop="productId" label="商品ID"></el-table-column>
        <el-table-column prop="productName" label="商品名称"></el-table-column>
        <el-table-column prop="type" label="充值金额">
            <template slot-scope="scope">
                <span>{{scope.row.productPrice}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="createTime" label="充值时间" sortable>
          <template slot-scope="scope">
            <span>{{scope.row.createTime | formatDate}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="状态" sortable width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.orderStatus==1" class="success">支付成功</span>
            <span v-if="scope.row.orderStatus==0" class="danger">未支付</span>
          </template>
        </el-table-column>
      </el-table>
      <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    </div>
  </template>
  
  <script>
  import * as XLSX from 'xlsx';
  import pagination from '@/components/pagination'
  export default {
    data() {
      return {
        totalNum: 0,
        ruleForm: {status:0},
        searchForm: {
          pageNo: 1,
          pageSize: 10
        },
        tableData: [],
        typeOptions: {
            '1':'apple',
            '2':'pro-google',
            '3':'第三方支付',
            '4':'ETH',
            '5':'lite-google'
        },
        channelOptions:{
          'meta_go':'meta-go',
          'one_pay':'one-pay'
        },
        appOptions:{
          'net':'net',
          'free':'ai',
          'appstore':'appstore',
          'co':'co',
          'club':'club'
        },
        statusOptions:{
          '0':'未支付',
          '1':'支付成功'
        }
      };
    },
    components:{pagination},
    created() {
      this.getTableList();
    },
    methods: {
      async exportExcel(){
        var timestamp = 0
        let res = await this.$http.orderList({...this.searchForm,pageNo:1,pageSize:10000});
        if (res) {
          let tableData = res.result.data;
          const worksheet = XLSX.utils.json_to_sheet(tableData);
          // 创建一个新的工作簿
          const workbook = XLSX.utils.book_new();
          // 将工作表添加到工作簿
          XLSX.utils.book_append_sheet(workbook, worksheet, "People");
          // 导出工作簿（创建 XLSX 文件）
          XLSX.writeFile(workbook, "talkLog.xlsx");
        }
      },
      changeSort(val) {
        if (val.order) {
          this.searchForm.sortOrder = val.order === "ascending" ? "ascend" : "descend";
          this.searchForm.sortField = val.prop;
        } else {
          this.searchForm.sortOrder = "";
        }
        this.getTableList(true);
      },
      async getTableList(val) {
        if(val) {
          this.searchForm.pageNo = 1
        }
        let searchForm = {...this.searchForm}
        let res = await this.$http.orderList({...searchForm});
        if (res) {
          this.tableData = res.result.data;
          this.totalNum = res.result.totalCount;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .icon{
    width: 60px;
  }
  </style>
  